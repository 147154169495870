@font-face {
  font-family: Asfalt;
  src: url(ABCAsfalt-Regular-Trial.woff);
}

@font-face {
  font-family: Favorit;
  src: url(ABCFavoritExpanded-Bold-Trial.woff);
}

* {
  margin: 0;
  padding: 0;
}

html, body {
  background-color: #000000;
}

section {
  height: 100vh;
}

#section-1, #section-2 {
  height: 100vh;
  display: grid;
  place-items: center;
}

.section-1--title {
  font-family: Favorit, "Arial";
  position: absolute;
  top: 45%;
  font-size: 5rem;
  color: #757498;
  text-transform: uppercase;
  z-index: 1;
  mix-blend-mode: exclusion;
}

.section-1--webgl, .section-2--webgl {
  outline: none;
}

.section-2--title {
  font-family: Favorit, "Arial";
  position: absolute;
  top: 145%;
  font-size: 5rem;
  color: #aa170d;
  text-transform: uppercase;
  z-index: 1;
  mix-blend-mode: exclusion;
}

@media (max-width: 576px) {
  .section-1--title, .section-2--title {
    font-size: 2rem;
  }
}

@media (min-width: 576px) {
  .section-1--title, .section-2--title {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .section-1--title, .section-2--title {
    font-size: 3rem;
  }
}

@media (min-width: 992px) {
  .section-1--title, .section-2--title {
    font-size: 5rem;
  }
}